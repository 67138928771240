import React, { useEffect, useRef } from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"

//logos de images
import image1 from "@components/pageFunctionalities/pageWhatsappPrecio/conversacion/image/image1.png"
import image4 from "@components/pageFunctionalities/pageWhatsappPrecio/conversacion/image/image2.png"
import image5 from "@components/pageFunctionalities/pageWhatsappPrecio/conversacion/image/image3.png"
import image6 from "@components/pageFunctionalities/pageWhatsappPrecio/conversacion/image/image4.png"

import useNearScreen from "@hooks/useNearScreen"

//data de images
const data = [
  {
    image: image1,
    title: "Conversación de servicio",
    textbody:
      "Iniciada por el usuario",
  },
  {
    image: image4,
    title:
      "Conversación por utilidad",
    textbody:
      "Iniciada por la empresa",
  },
  {
    image: image5,
    title: "Conversación de autenticación",
    textbody:
      "Iniciada por la empresa",

  },
  {
    image: image6,
    title: "Conversaciones de marketing",
    textbody:
    "Iniciada por la empresa",
  },
]

const Conversacion = () => {
  const { isNearScreen, fromRef } = useNearScreen({ distance: "10px" })
  const sliderRef = useRef()

  useEffect(() => {
    if (isNearScreen) sliderRef.current.slickPlay()
  }, [isNearScreen])

  const activateSliderTestimonies = {
    arrows: true,
    autoplay: false,
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 14000,
  }

  return (
    <section className="container-conversacion">
      <div className="container-conversacion-cards" ref={fromRef}>
        <Slider {...activateSliderTestimonies} ref={sliderRef}>
          {data.map((elemento, i) => {
            return (
              <section
                key={i}
                className="container-conversacion-cards-item"
              >
                <section>                
                  <p className="container-conversacion-cards-item-title">
                      {elemento.title}
                  </p>
                  <p className="container-conversacion-cards-item-text">
                    {elemento.textbody}
                  </p>
                </section>
                
                <img
                    className={"container-conversacion-cards-item-img"}
                    loading="lazy"
                    src={elemento.image}
                    alt="article"
                  />
              </section>
            )
          })}
        </Slider>
      </div>
    </section>
  )
}

export default Conversacion