/* eslint-disable react/jsx-key */
import React from "react"

//iconos poteciamos operaciones
import imageConversionsOne from "@components/pageFunctionalities/pageWhatsappPrecio/conversions/images/imageOne.webp"
import imageConversionsTwo from "@components/pageFunctionalities/pageWhatsappPrecio/conversions/images/imageTwo.webp"
import imageConversionsThree from "@components/pageFunctionalities/pageWhatsappPrecio/conversions/images/imageThree.webp"
import imageConversionsFour from "@components/pageFunctionalities/pageWhatsappPrecio/conversions/images/imageFour.webp"

import imageVideo from "@components/sharedComponents/bannerVideo/image/imageWhatsa.jpg"

import whatsapp from "@components/pageFunctionalities/pageWhatsappPrecio/intro/image/whatsapp.svg"

import { TitleUnderline } from "./intro/titleUnderline"


let data = {
  start: {
    logo: whatsapp,
    title: <h1 className="title-whatsapp">
      Conoce los <TitleUnderline underline="precios" /> <br /> de WhatsApp Business API
    </h1>,
    textbody: "En otros lugares tendrás que pagar los costos de alta. Pero aquí, solo tendrás que pagar por consumo. Por eso, conoce qué debes asumir realmente. Si ya te animaste, ponte en contacto con un agente.",
    textbutton: "¡Quiero la API de WhatsApp Business!",
    linkbutton: "/whatsapp-business-api/contacto-whatsapp-api/",
  },
  contacts: {
    title: "¡Obtén la API de WhatsApp Business gratis!",
    textbody: "No gastes de más para mejorar tus campañas de WhatsApp Marketing. Invierte solo en lo que consumes y olvídate de los precios de instalación.",
    href: "/hablemos/",
    btn: "¡Quiero obtener la API!",
  },
  footer: {
    product: "PRODUCTOS",
    productDesktopOne: "Conversations: Atención al cliente omnicanal y chatbots.",
    productMobileOne: "Conversations",
    linkone: "/conversations/",
    productDesktopTwo: " Contact Center: Software en la nube para Contact Center.",
    productMobileTwo: "Contact Center",
    linktwo: "/contact-center/",
    productDesktopFour: "Validation: Software de validación de teléfono.",
    productMobileFour: "Validation",
    linkfour: "/validation/",

    solution: "SOLUCIONES",
    solutionOne: "Atención al cliente",
    linkfive: "/atencion-al-cliente/",
    solutionTwo: "Telemarketing",
    linksix: "/marketing-ventas/",
    solutionThree: "Contact Center",
    linkseven: "/contact-center/",
    solutionFour: "Cobranzas",
    linkeight: "/software-cobranzas/",
    solutionFive: "WhatsApp",
    linkwhatsapp: "/whatsapp-business-api/",
    linkchatbots: "/chatbots-online/",
    solutionSix: "Chatbots",
    linkinstagram: "/chatbot-instagram/",
    solutionSeven: "Instagram",
    linkwebchat: "/chat-en-vivo-web/",
    solutionEight: "WebChat",

    company: "EMPRESA",
    companyOne: "Nosotros",
    linknine: "/nosotros/",
    companyTwo: "Casos de éxito",
    linkten: "/casos-de-exito/",
    companyThree: "Blog",
    linkeleven: "https://beexcc.com/blog/",
    companyFour: "Hablemos",
    linktwelve: "/hablemos/",
    companySix: "Documentación",
    linkdocs: "https://beexcc.com/docs/",
    companyFive: "Recursos",
    linktthirteen: "/recursos/",

    blog: "BLOG",
    blogone: "¿Cómo elegir el mejor software para call center?",
    linkblogone: "https://beexcc.com/blog/mejor-software-call-center/",

    blogtwo: "Plataforma Omnicanal: Qué es y cómo funciona",
    linkblogtwo: "https://beexcc.com/blog/plataforma-omnicanal/",

    blogthree: "¿Qué es WhatsApp Business API? - Guía completa 2023",
    linkblogthree: "https://beexcc.com/blog/que-es-whatsapp-business-api/",

    blogfour:
      "¿Cómo elegir el mejor chatbot? 5 características que debe cumplir",
    linkblogfour: "https://beexcc.com/blog/mejor-chatbot/",

    blogfive: "9 características que las herramientas de telemarketing",
    linkblogfive: "https://beexcc.com/blog/herramientas-telemarketing/",

    masblog: "Conoce más sobre nuestro blog",
    linkmasblog: "https://beexcc.com/blog/",

    address: "Jirón Pachacutec 1315, Of. 503, Jesús María, Lima, Perú",
    phone: "(+511) 277-4188",
    mail: "hi@beexcc.com",
    copyright: "Copyright© 2024 Beex Perú. Todos los derechos reservados.",
    privacyPolicy: "Política de Privacidad",
    privacyPolicyHref: "/politicas-privacidad",
    cookiesPolicy: "Política de Cookies",
    cookiesPolicyHref: "/politicas-cookies",
  },
  bannerVideo: {
    title: "¿Cómo obtengo la API de WhatsApp Business?",
    description: "<p>En el siguiente video, aprenderás a solicitar la API de WhatsApp Business de la manera más sencilla. Pero, primero, crea una cuenta de Beex para seguir este tutorial. <br /> <br />    Si quieres tener una atención mucho más personalizada, puedes solicitar una reunión con uno de nuestros agentes </p>",
    image: imageVideo
  },
  conversions: [
    {
      title: "Crea agentes IA",
      img: imageConversionsOne,
      text:
        "Crea un autoservicio 24/7 con agentes IA dentro de WhatsApp para gestionar eficientemente las consultas repetitivas.",
    },
    {
      title: "Enviar mensajes masivos",
      img: imageConversionsTwo,
      text:
        "Envía mensajes promocionales, de seguimiento, de confirmación y mucho más. Personaliza tu atención y fideliza más.",
    },
    {
      title: "Integrarlo con tus herramientas",
      img: imageConversionsThree,
      text:
        "Con la API podrás adherir tus plataformas internas como CRM o ERP a WhatsApp. Así potencias tu atención por esta app.",
    },
    {
      title: "Gestión multiagente",
      img: imageConversionsFour,
      text:
        "Ya no dependas de un solo celular. Con la API podrás gestionar tu atención desde diferentes dispositivos al mismo tiempo.",
    },
  ],
}

export default data
