/* eslint-disable no-undef */
import React, { useState } from "react"
import data from "@components/pageFunctionalities/pageWhatsappPrecio/data"
import Footer from "@components/sharedComponents/headerAndFooter/footer/footer"
import FooterMobile from "@components/sharedComponents/headerAndFooter/footer/footerMobile"
import Header from "@components/sharedComponents/headerAndFooter/header/header"
import Start from "@components/pageFunctionalities/pageWhatsappPrecio/intro/intro"
import BannerCookies from "@components/sharedComponents/bannerCookies/bannerCookies"
import BannerVideo from "@components/sharedComponents/bannerVideo/bannerVideo"
import BannerContact from "@components/sharedComponents/bannerContact/bannerContact"
import Conversions from "@components/pageFunctionalities/pageWhatsappPrecio/conversions/conversions"
import Question from "@components/pageFunctionalities/pageWhatsappPrecio/question/question"
import Escaner from "@components/pageFunctionalities/pageWhatsappPrecio/escaner/escaner"
import Calculador from "@components/pageFunctionalities/pageWhatsappPrecio/calculador/calculador"
import Conversacion from "@components/pageFunctionalities/pageWhatsappPrecio/conversacion/conversacion"
const TemplatePageWhatsappPrecio = ({ location }) => {

  //Video youtube
  const [video, setVideo] = useState(false)
  const openVideo = value => {
    setVideo(value)
  }

  //velocidad de entrada de textos y animaciones para desktop y mobile
  const windowsWidth = true

  return (
    <div className="fnd">
      <section>
        <BannerCookies />
        <Header
          path="/productos/"
          location={location}
          windowsWidth={windowsWidth}
        />
        {video ?
          <>
            <button
              className="button-video-youtube-beex"
              onClick={() => {
                openVideo(false)
              }}
            >
              X
            </button>
            <div className="container-video-youtube-beex">
              <div className="container-video-youtube-beex-background"></div>
              <div className="container-video-youtube-beex-iframe">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/hnr7sfYe210?autoplay=1"
                  title="Beex Teams"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </> : ""}
        <div className="container">
          <Start data={data.start} location={location} />
          <Question />
          <Conversacion />          
          <Calculador />
          <Conversions data={data.conversions} location={location} />
          <BannerVideo openVideo={openVideo} data={data.bannerVideo} />
          <Escaner />
          <BannerContact data={data.contacts} location={location} />
          <Footer data={data.footer} location={location} />
          <FooterMobile data={data.footer} location={location} />
        </div>
      </section>
    </div>
  )
}

export default TemplatePageWhatsappPrecio
